<template>
  <app-nav @onload="onLoad" />
  <div v-if="loading" class="loading"></div>
  <div v-else class="userinfo scroll">
    <user-profile :user="user"/>
    <user-password/>
    <withdrawal/>
  </div>
</template>

<script>
// @ is an alias to /src
import AppNav from '@/components/AppNav.vue'
import UserProfile from '@/components/UserProfile.vue'
import UserPassword from '@/components/UserPassword.vue'
import Withdrawal from '@/components/Withdrawal.vue'

export default {
  name: 'UserInfo',
  components: {
    AppNav,
    UserProfile,
    UserPassword,
    Withdrawal,
  },
  data() {
		return {
      user: false,
      loading: true
		}
	},
  methods: {
    onLoad(user) {
      this.user = user;
      this.loading = false;
    }
  }
}
</script>
