<template>
<div class="withdrawal">
<h2>退会</h2>
<div v-if="loading" class="loading"></div>
<div v-if="step === 0">
<button @click="step1">退会する</button>
</div>
<div v-else-if="step === 1">
<p>
退会すると、通知条件等の記録が全てなくなります。
有料会員の場合、次回から引き落としはありませんが、<strong>既に引き落とされた分の返金はしません</strong>。
承諾する場合、以下をチェックしてください。
</p>
<label><input type="checkbox" v-model="agreed"/> 上記のことを承諾する。</label><br/>
<button :disabled="!agreed" @click="finish">退会を完了</button>
</div>
</div>
</template>

<script>
import axios from 'axios'
import config from '@/config'

export default {
	name: 'Withdrawal',
  props: [
    'user'
  ],
	data() {
		return {
				step: 0,
				agreed: false,
				loading: false
		}
	},
  watch: {
    user(to) {
    }
  },
	methods: {
    step1() {
			this.step = 1;
    },
		finish() {
			this.loading = true;
			axios.post(config.API+'withdrawal.php', {
      },
			{
				withCredentials: true
			}).then(res => {
        if (res.data.status == 'success') {
					this.$router.push({path: '/', force: true});
					return;
        }
        alert(res.data.message);
				this.loading = false;
      });
		}
	}
}
</script>

<style scoped>
.withdrawal {
	border: 4pt solid #144E94;
	padding: 4pt;
	margin: 4pt auto;
	text-align: center;
	width: 320pt;
	max-width: 100%;
}
.withdrawal p {
	font-weight: bold;
	color: Red;
}
.withdrawal p strong {
	border-bottom: 2px dashed;
}
.withdrawal label * {
	vertical-align: middle;
}
</style>
