<template>
<div class="user-password">
<h2>パスワード変更</h2>
<div v-if="loading" class="loading"></div>
<div v-else-if="updated">
	パスワードを変更しました
</div>
<div v-else>
	<label>
	変更前パスワード<br/>
	<input type="password" v-model="oldPassword" />
	</label><br/>
	<label>
	変更後パスワード<br/>
	<input type="password" v-model="password" />
	</label><br/>
	<label>
	変更後パスワード再入力<br/>
	<input type="password" v-model="password2" />
	</label><br/>
	<button @click="update">更新</button>
</div>
</div>
</template>

<script>
import axios from 'axios'
import config from '@/config'

export default {
	name: 'UserPassword',
	data() {
		return {
			updated: false,
			oldPassword: '',
			password: '',
			password2: '',
			loading: false
		}
	},
	methods: {
    update() {
			this.loading = true;
      axios.post(config.API+'update-password.php', {
        oldPassword: this.oldPassword,
        password: this.password,
        password2: this.password2,
      },
			{
				withCredentials: true
			}).then(res => {
        if (res.data.status == 'success') {
          this.updated = true;
        }
        else {
          alert(res.data.message);
        }
				this.loading = false;
      });
    }
	}
}
</script>

<style scoped>
.user-password {
	border: 4pt solid #144E94;
	padding: 4pt;
	margin: 4pt auto;
  text-align: center;
	width: 320pt;
	max-width: 100%;
}
.user-password input,
.user-password button {
	margin: 4pt 0;
}
</style>
