<template>
<div class="user-profile">
<h2>会員情報</h2>
<div v-if="loading" class="loading"></div>
<div v-else-if="updated">
	{{ message }}
</div>
<div v-else>
	<label>名前<br/><input v-model="name"/></label><br/>
	<label>メールアドレス<br/><input v-model="mail"/></label><br/>
	<button @click="update">更新</button>
</div>
</div>
</template>

<script>
import axios from 'axios'
import config from '@/config'

export default {
	name: 'UserProfile',
  props: [
    'user'
  ],
	data() {
		return {
				name: this.user.name,
				mail: this.user.mail,
				updated: false,
				loading: false
		}
	},
  watch: {
    user(to) {
      this.name = to.name;
      this.mail = to.mail;
    }
  },
	methods: {
    update() {
			this.loading = true;
      axios.post(config.API+'update-profile.php', {
        name: this.name,
        mail: this.mail,
      },
			{
				withCredentials: true
			}).then(res => {
        if (res.data.status == 'success') {
          this.updated = true;
					this.message = res.data.message;
        }
        else {
          alert(res.data.message);
        }
				this.loading = false;
      });
    }
	}
}
</script>

<style scoped>
.user-profile {
	border: 4pt solid #144E94;
	padding: 4pt;
	margin: 4pt auto;
	text-align: center;
	width: 320pt;
	max-width: 100%;
}
.user-profile input,
.user-profile button {
	margin: 4pt 0;
}
</style>
